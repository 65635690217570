@import '_colors.scss';

.co.cart-item{
	display: flex;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid darken($COCOAN_YELLOW, 3%);

	.product-image{
		min-width: 100px;
		width: 100px;
		margin-right: 15px;
	}
	.info{
		.name{
			font-size: 16px;
			// font-weight: bold;
		}
		.price{
			margin-top: 10px;
			display: flex;
			font-size: 16px;
			font-weight: bold;

			.discounted{
				text-decoration: line-through;
				color: gray;
				font-weight: normal;
			}
			.final{
				margin-right: 10px;
				font-weight: bold;
				color: brown;
			}
		}
		.size{
			color: lighten(black, 40%);
		}
		.fr.remove.button{
			margin-left: 5px;
			min-width: 40px !important;
		}
		.quantity{
			display: inline-flex;
			align-items: center;
			position: relative;
			margin-top: 5px;
			select{
				display: block;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}
			.label{
				font-weight: bold;
				padding-right: 5px;
			}
			&:hover .label{
				text-decoration: underline;
				text-decoration-thickness: 2px;
				text-underline-offset: 3px;
			}
			i.icon{
				margin-left: 5px;
				font-size: 12px;
				line-height: 14px;
			}
			.label, select{
				cursor: pointer;
			}

			i.trash{
				margin-left: 5px;
			}
		}
	}

	&:last-child{
		border-bottom: 0;
	}

	@media screen and (max-width: 500px){
		.product-image{
			min-width: 80px;
			width: 80px;
		}
	}
}