.co.product-report-screen{
	.fr.groupper{
		.toolbar.section{
			margin-top: 0;
			justify-content: center;
			display: flex;
			height: 40px;

			.fr.input .fr.calendar{
				text-transform: none;
				.date, .time{
					font-weight: normal;
				}
			}

			.fr.input input{
				background-color: white;
				border-radius: 0;
				border-top: 0;
				border-bottom: 0;
				text-align: center;
				width: 120px;
			}
			i.icon{
				font-size: 14px;
				width: 14px;
				margin: 0 10px;
			}

			> .dropdown{
				$BORDER_CONFIG: 1px solid #DDD;
				font-size: 14px;
				padding: 7px 5px 5px 15px;
				background-color: white;
				border-left: $BORDER_CONFIG;
				border-right: $BORDER_CONFIG;
				height: 38px;
				margin-left: 15px;
				cursor: pointer;
				font-weight: normal;
				display: flex;
				align-items: center;
				text-transform: none;
				i.icon{
					margin-right: 5px;
					margin-left: 5px;
				}
				.amount{
					margin-right: 5px;
					font-weight: bold;
				}
			}

			> .fr.button{
				padding: 8px 20px;
				width: 100px;
				text-align: center;
				margin-left: 15px;
			}
		}
		.chart{
			padding: 10px;
			height: 500px;
			&.empty{
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}