.co.categories-screen{

	.categories{
		max-width: calc(900px);
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		padding: 15px;

		.co.category{
			width: calc(33% - 4px);

		}
		@media screen and (max-width: 650px){
			padding: 5px;
			.co.category{
				width: calc(50% - 6px);
			}
		}
	}

	.toolbar{
		display: flex;
		max-width: 600px;
		margin: auto;
		align-items: center;
		padding: 0 10px;

		.options{
			display: flex;
		}

		.fr.field{
			flex-grow: 100;
			margin: 0;
		}
		.fr.button{
			margin-left: 5px;
			padding: 8px 15px;

			i.icon{
				line-height: 20px;
			}
		}
	}
}

.ui.sort-categories-list.modal .content{
	.list{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.item{
			width: calc(33%);
			&.dragged{
				background-color: red;
				z-index: 10000;
			}
			.co.category{
				user-select: none;
				width: auto;
			}
		}
	}
}
.item.sort-categories-list-item-dragged{
	z-index: 100000;
}

.co.product-edit-screen{
	.fr.toolbar{
		margin: 0 -20px;
		border-radius: 0;
		box-shadow: none;
		border: 0;
		.item{
			color: black;
			border-radius: 0;
		}
	}
}

@media screen and (max-width: 675px) {
	.toolbar{
		width: 100%;
		display: flex;
		flex-direction: column;
		input{
			width: 300px !important;
 			margin-bottom: 10px;
		}
		.options{
			margin: 5px;
		}
	}
}