.co.order-view-screen{
	overflow: scroll;
	padding: 0 10px;	
	
	.fr.table tr.tags{
		td:last-child{
			.fr.label{
				margin-right: 5px;
				min-width: 90px;
				&.transparent{
					background-color: #f0f0f0;
					font-size: 12px;
					.fr.icon{
						font-size: 11px;
					}
					&:hover{
						background-color: #e7e7e7;
					}
				}
			}
		}
	}
}