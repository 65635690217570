.co.creator{
	height: calc(100vh - 40px);
	margin-top: -10px;
	display: flex;
	> * {
		position: relative;
		padding-top: 50px;
	}

	> * > .head{
		max-width: inherit;
		position: fixed;
		font-size: 20px;
		font-weight: bold;
		text-transform: uppercase;
		padding: 15px;
		height: 50px;
		background-color: red;
		width: 100%;
		background-color: #f0f0f0;
		border-bottom: 1px solid #DDD;
		top: 40px;
		z-index: 5;
		display: flex;
		align-items: center;
		display: flex;
		align-items: center;

		.text{
			flex-grow: 100;
		}
	}

	.contents{
		position: relative;
		overflow-y: scroll;
		flex-grow: 100;
		> .head{
			padding: 10px;
			width: 100%;
			padding-right: 365px;

			.input{
				margin-top: 10px;
			}

			.fr.field{
				margin: 0;
			}
		}
		.categories,.products{
			display: flex;
			flex-wrap: wrap;
			padding: 15px;

			.co.category{
				width: calc(33% - 8px);
			}
			.co.product.card{
				width: calc(25% - 10px)
			}
		}
	}
	.cart{
		position: relative;
		min-width: 350px;
		width: 350px;
		background-color: #fafafa;
		border-left: 1px solid #EEE;
		display: flex;
		flex-direction: column;

		> .head{
			border-left: 1px solid #DDD;
			margin-left: -1px;
		}
		.items{
			flex-grow: 100;

			.fr.header{
				margin-top: 20px;
				color: gray;
			}
			.co.cart-item{
				padding: 10px;
				padding-top: 0;
				border-bottom-color: #e6e6e6;
				.co.product-image .image{
					border: 1px solid #EEE;
				}
				.meta{
					color: gray;
				}
				.info{
					.price{
						margin-top: 5px;
					}
				}
				
				&:first-child{
					padding-top: 10px;
				}
			}
		}
		.totals{
			background-color: darken(#fafafa, 2%);
			border-top: 1px solid #DDD;
			width: 100%;
			padding: 15px;

			.amount{
				display: flex;
				font-size: 16px;
				margin-bottom: 15px;
				.label{
					flex-grow: 100;
				}
				.value{
					font-weight: bold;
				}
			}
			.fr.button{
				margin-top: 20px;
			}
		}
	}

}
.fr.fields{
	.fr.input{
		.fr.popup-container{
			width: 100% !important;
		}
	}
}

@media screen and (max-width: 790px) {
	.cart	 {
		position: fixed !important;
		top: 0 !important;
		width: 100% !important;
		height: 100% !important;
		z-index: 5 !important;
		> .head{
			width: 100% !important;
			.icon {
				position: absolute;
				right: 0;
			}
		}
		> .items{
			margin-top: 50px;
			overflow: scroll;
		}
	}
	.cart.show {
		transition: right .5s !important;
		display: flex !important;
	}
	.cart.hide {
		transition: left .5s !important;
		display: none !important;
	}
	.contents{
		> .head{
			padding: 10px !important;
			width: 100% !important;
			.input{
				margin-top: 10px;
			}
			.button{
				margin-left: 10px;
			}
		}
	}
}