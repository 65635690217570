.co.dashboard{
	margin-top: -15px;
	width: 100%;
	height: calc(100vh - 40px);
	display: flex;

	.toolbar{
		display: flex;
		align-items: center;
		padding-bottom: 10px;
		.fr.header{
			flex-grow: 100;
			.content{
				padding-left: 0;
			}
		}
	}

	.fr.sidebar .sidebar{
		height: 100%;
		margin-top: 5px;
		.item{
			margin: 0;
			border-radius: 0;
			.name{
				font-weight: bold;
			}

			.meta{
				font-size: 14px;
				color: gray;
				font-weight: normal !important;

				&.placeholder{
					height: 18px;
					background-color: #f0f0f0;
					border-radius: 100px;
				}
			}

			&.active{
				background-color: #f0f0f0;
				.meta.placeholder{
					background-color: #e7e7e7;
				}
			}
		}
	}
	.content{
		padding: 15px;
		flex-grow: 100;
		overflow-y: scroll;

		.fr.groupper.contents{
			width: 100%;
			max-width: 100%;
			margin: 0;
			padding-bottom: 0;
			overflow-y: hidden;
			overflow-x: scroll;

			table.fr.table{
				min-width: 100%;
			}
		}
	}

	table.fr.table tr td{
		.fr.label{
			font-size: 11px;
			padding: 2px 8px;
			min-width: 90px;
		}
	}

	.co.searchbar{
		position: absolute;
		top: 40px;
		left: 0;
		width: 100%;
		height: 200px;
		background-color: white;
		border-bottom: 1px solid #CCC;
		z-index: 3;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.fr.input{
			width: 100%;
			max-width: 500px;
			text-align: center;
			input{
				font-size: 20px;
				padding: 15px;
				text-align: center;
			}
		}

		.fr.button{
			width: 200px;
		}
	}

	.co.dimmer{
		position: absolute;
		top: 40px;
		left: 0;
		width: 100%;
		height: calc(100% - 40px);
		background-color: rgba($color: #000000, $alpha: 0.4);
		z-index: 2;
	}

	@media screen and (max-width: 675px) {
		display: block;
		.content{
			padding: 0;
			.toolbar{
				padding: 5px 10px 0 10px;
			}
		}
	}
	@media screen and (max-width: 420px){
		.toolbar{
			margin-bottom: 5px;
			.fr.header{
				margin: 0;
				font-size: 20px;
			}
		}
	}
}