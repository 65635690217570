$NAVBAR_COLOR: black;

body{
	background-color: #f5f5f5;
	font-family: Roboto;
}

.ar.navbar{
	width: 100%;
	background-color: $NAVBAR_COLOR;
	display: flex;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	position: fixed;
	justify-content: left;
	top: 0;
	z-index: 10000;
	.right {
		margin-left: auto;
	}

	.left{
		.hamburger.item{
			min-width: 0;
			padding: 0;
			font-size: 26px;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover{
				background-color: transparent;
			}
		}
		.logo.item{
			height: 23px;
			padding: 0 10px;
			font-weight: bold;
			font-size: 20px;
			&:hover{
				background-color: transparent;
			}
			margin-right: 10px;
		}
	}
	> *{
		display: flex;
		align-items: center;
		margin: 0 5px;
	}
	* > .item{
		display: flex;
		align-items: center;
		padding: 10px 10px;
		color: white;
		text-decoration: none;
		&.active{
			background-color: lighten($NAVBAR_COLOR, 5);
		}
		&.icon{
			width: 40px;
			padding: 0;
			text-align: center;
			justify-content: center;
			i.icon{
				margin: 0;
			}
		}
		&.dropdown{
			.menu{
				min-width: 100%; 
				border-top-left-radius: 0; 
				border-top-right-radius: 0;
			}
			i.icon{
				font-size: 16px;
				line-height: 16px;
				margin-left: 5px;
				margin-right: 0;
			}
		}
	}

	* > .item:hover, > .left .icon:hover, > .left .hamburger:hover{
		background-color: lighten($NAVBAR_COLOR, 10);
		cursor: pointer;
	}

	@media screen and (min-width: 450px){
		.left .hamburger.item{
			display: none;
		}
		.right .mobile{
			display: none;
		}
	}
	@media screen and (max-width: 450px){
		.right .desktop{
			display: none;
		}
	}
}


.ar.navsidebar{
	position: fixed;
	top: 40px;
	left: 0;
	width: 75%;
	max-width: 300px;
	background-color: #151717;
	height: calc(100vh - 40px);
	overflow-y: scroll;
	padding-top: 5px;
	z-index: 1000;
	.item{
		min-width: 280px;
		font-size: 24px;
		padding: 15px;
		cursor: pointer;
		display: block;
		color: white;
		border-bottom: 1px solid lighten(#151717, 5);
		&.dropdown{
			padding: 0;
			.content{
				display: flex;
				align-items: center;
				.text{
					flex-grow: 100;
					padding: 15px;
				}
				i.dropdown.icon{
					margin-right: 10px;
					padding-left: 10px;
					margin-right: 15px;
				}
			}
			.menu{
				.item{
					display: block;
					background-color: #1e1e24;
					padding: 15px;
					font-size: 18px;
					color: white;
					margin-bottom: 0;
				}
			}
		}
	}
}

.ar.closable{
	position: fixed;
	top: 40px;
	left: 0;
	width: 100%;
	height: calc(100vh - 60px);
	background-color: rgba(0,0,0,0.8);
	z-index: 999;
}
 
@media screen and (max-width: 768px) {
	table.fr.selectable{
		max-width: 400px;
	}

	table.fr.selectable th.hide-on-mobile {
		display: none;
	}

	table.fr.selectable td.hide-on-mobile {
		display: none;
	}
	table.fr.selectable td.collapsing.hide-on-mobile {
		display: none;
	}
}

.fr.button.icon{
	min-width: 30px !important;
	i{
		margin-right: 0;
		margin-top: 0;
	}
	&.tiny{
		min-width: 30px !important;
	}
}

.cb.toolbar{
	background-color: white;
	border-radius: 8px;
	display: flex;
	justify-content: stretch;
	margin-bottom: 10px !important;
}

.cb.toolbar > .Titem{
	padding: 10px;
	width: 100% !important;
	height: 100% !important;
	text-align: center !important;
	cursor: pointer;
	border-right: 1px solid #EEE;
	font-weight: bold;
}
.cb.toolbar .ui.dropdown.item .menu{
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}
.cb.toolbar .item:last-child{
	border-right: none;
}
.cb.toolbar .Titem:hover{
	background-color: #f0f0f0;
}


.fr.option.divider{
	width: calc(100% + 30px);
	background-color: #f0f0f0;
	margin: 20px -20px 15px -20px !important;
	padding: 10px 15px;
	border-top-width: 0 !important;
	height: auto !important;
	font-weight: bold;
}
.fr.option .fr.input{
	margin-bottom: 0;
	margin-left: 15px !important;
	min-width: 250px;
}

.fr.groupper .section.head{
	text-transform: uppercase;
	text-align: left;
	font-size: 18px;
	color: rgba(0,0,0,.8);
	font-weight: bold;
}

.w-md-editor{
	border-radius: 0;
	textarea.w-md-editor-text-input,.w-md-editor-content,.w-md-editor-area{
		border-radius: 0 !important;
		color: black !important;
		background-color: none !important;
		-webkit-text-fill-color: black;
		&:hover{
			color: black !important;
			border-radius: 0 !important;
			border: none;
		}
		&:focus{
			background-color: white !important;
			color: black !important;
		}
	}
	.wmde-markdown-color{
		color: black !important;
	}
	.w-md-editor-toolbar ul li button{
		width: 30px;
		height: 30px;
		svg{
			height: 16px;
			width: 16px;
		}
	}
	// .w-md-editor-text{
	// 	height: 100%;
	// }
}