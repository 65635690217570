.co.products{
	height: calc(60vh - 40px);
	display: flex;

	> * > .head{
		max-width: inherit;
		font-size: 20px;
		font-weight: bold;
		text-transform: uppercase;
		padding: 15px;
		height: 50px;
		background-color: red;
		background-color: #f0f0f0;
		border-bottom: 1px solid #DDD;
		top: 40px;
		z-index: 5;
		display: flex;
		align-items: center;
		display: flex;
		align-items: center;

		.text{
			flex-grow: 100;
		}
	}

	.contents{
		position: relative;
		overflow-y: scroll;
		flex-grow: 100;
		> .head{
			padding: 10px;
			width: 100%;

         .input{
            margin-top: 10px;
         }
		}
		.categories,.products{
			display: flex;
			flex-wrap: wrap;
			padding: 15px;

			.co.category{
				width: calc(33% - 8px);
            font-size: 8px;
			}
			.co.product.card{
				width: calc(33% - 10px);
            font-size: 11px !important;
			}
		}
	}
	.cart{
		min-width: 350px;
		width: 350px;
		background-color: #fafafa;
		border-left: 1px solid #EEE;
		display: flex;
		flex-direction: column;
      overflow-y: scroll;

		> .head{
			border-left: 1px solid #DDD;
			margin-left: -1px;
		}
		.items{
			flex-grow: 100;

			.fr.header{
				margin-top: 20px;
				color: gray;
			}
			.co.cart-item{
				padding: 10px;
				padding-top: 0;
				border-bottom-color: #e6e6e6;
				.co.product-image .image{
					border: 1px solid #EEE;
				}
				.meta{
					color: gray;
				}
				.info{
					.price{
						margin-top: 5px;
					}
				}
				
				&:first-child{
					padding-top: 10px;
				}
			}
		}
		.totals{
			background-color: darken(#fafafa, 2%);
			border-top: 1px solid #DDD;
			width: 100%;
			padding: 15px;

			.amount{
				display: flex;
				font-size: 16px;
				margin-bottom: 15px;
				.label{
					flex-grow: 100;
				}
				.value{
					font-weight: bold;
				}
			}
			.fr.button{
				margin-top: 20px;
			}
		}
	}

}
.fr.fields{
	.fr.input{
		.fr.popup-container{
			width: 100% !important;
		}
	}
}