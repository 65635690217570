@import '_colors.scss';

.co.product-image{
	display: block;
	width: 300px;
	position: relative;

	.image{
		width: 100%;
		padding-top: calc((5 / 4 * 100%) - 5px);
		position: relative;
		border-radius: 13px;
		background-color: darken(#FFFFFF, 5%);

		img{
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 12px;
			transition: all 100ms;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
		}
		// height: 275px;
		// width: 220px;
		.message{
			position: absolute;
			top: 10px;
			left: 10px;
			color: #151717;
			background-color: $COCOAN_YELLOW;
			padding: 5px 15px;
			max-width: 75%;
			border-radius: 100px;
			font-weight: bold;
		}
	}
}