.fr.products-list.table{
	td,th{
		img{
			object-fit: cover;
			border-radius: 8px;
			width: 70px;
			height: calc(70px * 1.25);
		}
		@media screen and (max-width: 400px) {
			&.desktop{
				display: none;
			}
			img{
				border-radius: 4px;
				width: 30px;
				height: 30px;
			}
		}
	}
}

.co.images.product{
	display: flex;
	margin: -5px;
	flex-wrap: wrap;
	.image{
		cursor: pointer;
		display: block;
		width: 107px;
		height: 107px;
		border: 2px solid #CCC;
		border-radius: 12px;
		margin: 5px;
		object-fit: cover;
		&.add{
			background-color: #f8f8f8;
			display: flex;
			flex-direction: column;
			text-align: center;
			align-items: center;
			justify-content: center;
			border-style: dashed;
			&:hover{
				background-color: #f3f3f3;
			}
			i{
				margin: 0;
				font-size: 30px;
				color: #AAA;
			}
		}
		&.loading{
			background-color: #f8f8f8;
			display: flex;
			align-items: center;
		}
		&.primary{
			border-color: rgb(206, 39, 39);
			border-width: 4px;
		}
	}
	&.single{
		justify-content: center;
		.image{
			width: 207px;
			height: 207px;
		}
	}
	&.portrait{
		.image{
			height: calc(107px * 1.25);
		}
		&.single .image{
			height: calc(207px * 1.25);
		}
	}
	&.landscape{
		.image{
			height: 150px;
			width: 100%;
		}
	}
}