.co.login-screen{
	height: 75vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px;

	.fr.groupper{
		max-width: 400px !important;

		.head{
			justify-content: center;
			padding: 35px 10px;
			> img{
				height: 40px;
			}
		}

	}
}