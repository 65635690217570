@import '_colors.scss';

.co.product.card{
	display: block;
	margin: 5px;
	width: 300px;
	position: relative;

	.image{
		width: 100%;
		padding-top: calc((5 / 4 * 100%) - 5px);
		position: relative;
		border-radius: 13px;
		background-color: darken(#FFFFFF, 5%);
		border: 1px solid darken(#FFFFFF, 5%);

		img{
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 12px;
			transition: all 100ms;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
		}
		// height: 275px;
		// width: 220px;
		.message{
			position: absolute;
			top: 10px;
			left: 10px;
			color: #151717;
			background-color: $COCOAN_YELLOW;
			padding: 5px 15px;
			max-width: 75%;
			border-radius: 100px;
			font-weight: bold;
		}
	}
	.info{
		width: 100%;
		// background-color: blue;
		padding: 10px;
		color: black;
		.name{
			font-weight: bold;
			// background-color: red;
			font-size: 16px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow-x: hidden;
		}
		.price{
			margin-top: 5px;
			display: flex;
			font-size: 16px;
			font-weight: bold;

			.discounted{
				text-decoration: line-through;
				color: gray;
				font-weight: normal;
			}
			.final{
				margin-right: 10px;
				font-weight: bold;
				color: brown;
			}
			.size{
				font-size: 14px;
				margin-left: 5px;
				color: gray;
				font-weight: normal;
				flex-grow: 100;
				text-align: right;
			}
		}
	}

	&.link{
		cursor: pointer;
	}

	&.placeholder{
		.image{
			background-color: $PLACEHOLDER_COLOR;
			border-color: $PLACEHOLDER_COLOR;
		}
		.info{
			.name{
				height: 22px;
				background-color: $PLACEHOLDER_COLOR;
				border-radius: 100px;
				width: 52%;
			}
			.price{
				height: 18px;
				background-color: $PLACEHOLDER_COLOR;
				border-radius: 100px;
				width: 62px;
			}
		}

		&.active{
			.image{
				background-color: $PLACEHOLDER_ACTIVE_COLOR;
				border-color: $PLACEHOLDER_ACTIVE_COLOR;
			}
			.info .name, .info .price{
				background-color: $PLACEHOLDER_ACTIVE_COLOR;
			}
		}
	}
}