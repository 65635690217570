@import '_colors.scss';

.co.category{
	&.card{
		width: 300px;
		margin: 3px;
		border-radius: 12px;
		position: relative;
		transition: all 100ms;
		
		.content{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 3;

			.name{
				font-weight: bold;
				font-size: 2em;
				text-align: center;
				line-height: 1.1em;
				padding: 20px;
				text-transform: uppercase;
				font-style: italic;
				color: white;
				width: 100%;

				@media screen and (max-width: 800px){
					padding: 10px;
					font-size: 1.5em;
					line-height: 1.3em;
				}
				@media screen and (max-width: 500px){
					font-size: 1.3em;
					line-height: 1.2em;
				}
			}
		}

		.image{
			width: 100%;
			padding-top: calc((5 / 4 * 100%) - 5px);
			position: relative;
			border-radius: 13px;
			background-color: darken(#FFFFFF, 10%);
	
			img{
				width: 100%;
				height: 100%;
				display: block;
				border-radius: 12px;
				object-fit: cover;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 0;
			}
			.overlay{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 12px;
				z-index: 1;
				opacity: 0.4;
			}
		}

		&.all{
			.image{
				background-color: $COCOAN_YELLOW;
			}
			.content .name{
				color: black
			}
		}

		&.placeholder{
			background-color: $PLACEHOLDER_COLOR;
			.image{
				background-color: $PLACEHOLDER_COLOR;
			}
			&.active{
				background-color: $PLACEHOLDER_ACTIVE_COLOR;
				.image{
					background-color: $PLACEHOLDER_ACTIVE_COLOR;
				}
				.content .placeholder{
					background-color: darken($PLACEHOLDER_COLOR, 15%);
				}
			}
			.content{
				flex-direction: column;
				.placeholder{
					background-color: darken($PLACEHOLDER_COLOR, 10%);
					width: 87%;
					height: 40px;
					border-radius: 100px;
					margin-top: 4px;
					&:first-child{
						margin-top: 0;
					}
				}
			}
		}

		&:not(.placeholder){
			cursor: pointer;
			&:hover{
				transform: translateY(-3px);
				&:not(.all){
					.content .name{
						text-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
					}
				}
				.image .overlay{
					transition: all 600ms;
					opacity: 0.1;
				}
			}
		}
	}
}