.co.orders{
	.header{
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 10px;
		margin-top: 10px;
	}
	.title{
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 15px;
		margin-top: 10px;
		text-align: left;
	}
	.fr.items > .item > .container{
		display: flex;
		justify-content: space-between;
		text-align: start;
		width: 100%;
	}

	> .fr.groupper > .container{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		> .fr.groupper{
			width: 49%;
		}

		@media screen and (max-width: 600px) {
			display: block;
			> .fr.groupper{
				width: 100%;
				margin-bottom: 10px;
			}
		}
	}


	.date{
		
	}

	
	.fr.items{
		.container{
			display: flex;
			flex-direction: row;
			flex: 1;
			align-items: center;
			
			.name{
				display: flex;
				flex-direction: column;
				flex-grow: 100;

				i.icon{
					font-size: 14px;
					margin-right: 5px;
				}
				.date{
					line-height: 18px;
					font-size: 14px;
					color: rgb(91, 91, 91);
				}
			}

			.price{
				color: brown;
			}
			
			.location{
				text-align: right;
				margin-right: 5px;
			}
	
			> i.icon{
				text-align: right;
				margin: 0 !important;
				height: 20px;
				width: 30px;
				text-align: center;
				font-size: 20px;
			}

			&.shipped{
				.name{
					color: gray;
				}
				.location{
					color: gray;
				}
			}
		}
	}
}

.fr.groupper .section.head.small{
	font-size: 14px;
	height: 35px;
	margin-bottom: 5px;
	text-transform: none;
	&.top{
		margin-top: -21px;
	}
}